declare const $: any;
export function formOrderValidator() {
    'use strict';
    $(function () {
        var $demoMaskedInput = $('.demo-masked-input');
        $demoMaskedInput.find('.date').inputmask('dd/mm/yyyy', { placeholder: '__/__/____' });
    });
}



export const DateFormat = {
    parse: {
      dateInput: 'input',
      },
      display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'MM/DD/YYYY',
      monthYearA11yLabel: 'MMMM YYYY',
      language : 'fr'
      }
    };
  

   