import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Contentieux } from '../pages/contetieux/contentieux-model';
import { take, mergeMap, map, catchError, tap } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentieuxService {

  constructor(private http: HttpClient) { }
  _refreshNeeded$ = new Subject<void>();

  get refreshNeeded(){
    return this._refreshNeeded$;
  }
  get_list_contentieux(company_id:number,offset: number, limit: number) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.URL_BASE}/object/luxus.contentieux.model?fields=['resident','end_date','initial_amount','start_date','nbr_appartement','situation','additional_amount']&domain=[('company_id','=',${company_id})]&offset=${offset}&limit=${limit}`);
  }

  add_contentieux(contentieux: Contentieux) {
    const formData = new FormData();
    formData.append('resident', contentieux.resident.toString());
    formData.append('situation', contentieux.situation.toString());
    formData.append('initial_amount', contentieux.initial_amount.toString());
    formData.append('start_date', "'" + contentieux.start_date + "'");
    formData.append('end_date', "'" + contentieux.end_date + "'");
    formData.append('additional_amount', contentieux.additional_amount.toString());
    formData.append('company_id', contentieux.company_id.toString());
    formData.append('apprt_id', contentieux.nbr_appartement.toString());
    return this.http.post(`${environment.URL_BASE}/object/luxus.contentieux.model/add_contentieux`, formData);
  }

  update_contentieux_situation(contentieuxId: number, situation: number) {
    // tslint:disable-next-line:max-line-length
    return this.http
    .post(`${environment.URL_BASE}/object/luxus.contentieux.model/${contentieuxId}?vals={"situation":${situation}}`, null)
    .pipe(
      tap(() => {
        this._refreshNeeded$.next()
      })
    );
  }

  update_contentieux_amount(contentieuxId: number, additionalAmount: number) {
    // tslint:disable-next-line:max-line-length
    return this.http
    .post(`${environment.URL_BASE}/object/luxus.contentieux.model/${contentieuxId}?vals={"additional_amount":${additionalAmount}}`, null)
    .pipe(
      tap(() => {
        this._refreshNeeded$.next()
      })
    );
  }
  update_cont_amount(ammount:number,company_id: number, resident: number,situation:number) {
    // tslint:disable-next-line:max-line-length
    return this.http
    .post(`${environment.URL_BASE}/object/luxus.contentieux.model/update_ammount?ammount=${ammount}&company_id=${company_id}&resident=${resident}&situation=${situation}`, null)
    .pipe(
      tap(() => {
        this._refreshNeeded$.next()
      })
    );
  }
  // user = kw.get('user')
  //       amount_supp = kw.get('amount')
  //       company_id = kw.get('company_id')
  //       apprt_id = kw.get('apprt_id')
  //       date_from = kw.get('date_from')
  //       date_to = kw.get('date_to')
  add_invoice_litige(user, amount, companyId, apprtId, dateFrom, dateTo) {
    return this.http.post(`${environment.URL_BASE}/object/recover.table/add_invoice_litige?user=${user}&amount=${amount}
    &company_id=${companyId}&apprt_id=${apprtId}&date_from='${dateFrom}'&date_to='${dateTo}'`, null);
  }

  deblock_user(userId: number, contentieuxId: number) {
    console.log('contentieuxId : ');
    console.log(contentieuxId);
    return this.http.post(`${environment.URL_BASE}/object/res.users/${userId}?vals={'litigation_state':False}`, null).subscribe(
      res => {
        console.log('res : ', res);
      }
    );
  }

  exist_user(userId: number) {
    return this.http.get(`${environment.URL_BASE}/object/luxus.contentieux.model?domain=[('resident', '=', ${userId})]
    &fields=['resident']`);
  }
  delete_contentieux(contentieuxId: number) {
    return this.http.delete(`${environment.URL_BASE}/object/luxus.contentieux.model?ids=${contentieuxId}`).pipe(
      map(
        (res) => {
          return res;
        }
      ),
      catchError((err: any) => {
        return throwError('erreur delete');
      })
    )
    .pipe(
      tap(() => {
        this._refreshNeeded$.next()
      })
    );
  }
}
