export class Contentieux {
   id: number;
    nbr_appartement: Array<any>;
    resident: number | Array<any>;
    start_date: string | Date;
    end_date: string | Date;
    situation: any;
    initial_amount: number;
    additional_amount: number;
    company_id: number;
    constructor(id:number,nbr_appartement: Array<any>,
        resident: number | Array<any>,
        start_date: string | Date,
        end_date: string | Date,
        situation: any,
        initial_amount: number,
        additional_amount: number,
        company_id: number
        ) {
        this.id = id;
        this.nbr_appartement = nbr_appartement;
        this.resident = resident;
        this.start_date = start_date;
        this.end_date = end_date;
        this.situation = situation;
        this.initial_amount = initial_amount;
        this.additional_amount = additional_amount;
        this.company_id = company_id;
    }
}
